import React from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

const ConstructionPage = () => (
  <section id="hero" className="jumbotron">
    <Container>
      <Fade left duration={1000} delay={500} distance="30px">
        <h1 className="hero-title">
          {'Hi, my name is'}{' '}
          <span className="text-color-main">{'Rukayat Sadiq'}</span>
          <br />
          {"This website is under construction."}
        </h1>
      </Fade>
      <Fade left duration={1000} delay={1000} distance="30px">
        <p className="hero-cta">
          <span className="cta-btn cta-btn--hero">
            <a
              href="https://www.linkedin.com/in/rukayatsadiq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'Find me on LinkedIn'}
            </a>
          </span>
        </p>
      </Fade>
    </Container>
  </section>
);

export default ConstructionPage;